@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .text-title-xl {
    @apply w-full text-7xl font-bold text-center;
  }

  .text-title-lg {
    @apply w-full text-5xl font-bold text-center;
  }

  .text-title-md {
    @apply w-full text-3xl font-bold text-center;
  }

  .text-title-sm {
    @apply w-full text-2xl font-bold text-center;
  }

  .wave {
    @apply absolute w-[1500px] h-[600px] z-0 rounded-full;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

html {
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Roboto", sans-serif;
  background-image: linear-gradient(
    170deg,
    #150b44 0%,
    #103b49 51%,
    #195753 100%
  );
  background-repeat: no-repeat;
}

.bg {
  background-image: linear-gradient(
    170deg,
    #150b44 0%,
    #103b49 51%,
    #195753 100%
  );
  background-repeat: no-repeat;
}

.banner {
  background-image: url("./assets/images/banner2.jpg");
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  margin-top: 100px;
  background-size: cover;
}

@media screen and (max-width: 3500px) {
  .banner {
    height: 600px;
  }
}

@media screen and (max-width: 1800px) {
  .banner {
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .banner {
    height: 300px;
  }
}
@media screen and (max-width: 640px) {
  .banner {
    height: 200px;
  }
}
@media screen and (max-width: 500px) {
  .banner {
    height: 150px;
  }
}
@media screen and (max-width: 200px) {
  .banner {
    height: 150px;
  }
}

.scroll-bar::-webkit-scrollbar {
  width: 1px;
}

.scroll-bar-r {
  direction: rtl;
}
.scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.bg-about {
  background-image: url("./assets/images/BlogImage.jpg");
  height: 500px;
  max-height: 100%;
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-contact {
  background-image: url("./assets/images/family.jpg");
  height: 600px;
  max-height: 100%;
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
