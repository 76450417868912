.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-button-prev,
.swiper-button-next {
  color: green;
}

.swiper-pagination-bullet-active {
  background-color: green;
}
